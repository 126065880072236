import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"

/* Farms page, uses the Video > Rich Text > Stepped Navigation structure */

class Farms extends React.Component {
  componentDidMount() {
    // Inactivity()
  }
  render() {
    return(
      <Layout>
        <Helmet>
          <title>Meet MJN - Visit One Of Our Farms - Grazing</title>
        </Helmet>
        <main role="main" className="content-main">

          <section className="hero-area -general -video">
            <div className="video-player-wrapper">
              <div className="youtube-player-wrapper">
                <iframe className="youtube-player" id="youtube-video" title="video" width="540" height="304" src="https://www.youtube.com/embed/JWLhrPnE1Xs?controls=0&modestbranding=1&playsinline=1rel=0" allow="autoplay" frameBorder="0"></iframe>
              </div>
            </div>
          </section>

          <section className="rich-text -bg-white -with-stepped">
            <nav className="breadcrumb" role="navigation">
              <Link to="/event-experience" title="Event Experience"> 
                <i className="fas fa-arrow-left"></i>
                Event Experience
              </Link>
            </nav>
            <h2>How Grazing Works</h2>

            <p>The cows are spending over 300 days of the year outside eating grass and really our skills are generational skills handed down which is managing the grass in such a way that it is right throughout that period of time the cows have access to fresh grass every day they are allocated a certain amount of grass for a 24-hour period and then over 20 to 21 day timeframe they rotate around through the different pastures ensuring  that each day they get fresh grass nutritious grass and lots of it!</p>
          </section>

          <ol className="stepped-navigation">
            <li className="stepped-navigation__item -active"><span>Grazing</span></li>
            <li className="stepped-navigation__item"><Link to="/event-experience/family"></Link></li>
            <li className="stepped-navigation__item"><Link to="/event-experience/milk"></Link></li>
          </ol>
        </main>
      </Layout>
    )
  }
}

export default Farms